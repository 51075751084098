import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ZeusModules from "../components/zeusModules"
import ProjectNav from "../components/projectNav"
import HeaderImage from "../components/headerImage"
import HeadingCta from "../components/headingCta"
import styles from "./case-study.module.scss"
import WorkNav from "../components/workNav"

export default props => {
  const project = props.data.craft.work
  const seo = project.seomatic
  const coverImages = project.coverImages[0]
  const headingCta = project.headingCta[0]

  return (
    <Layout seo={seo}>
      <div className={styles.headerImg}>
        <HeaderImage data={coverImages} />
      </div>
      <div className={`page-content-module ${styles.pageContent}`}>
        <div className="wrapper">
          <div className={styles.title}>
            <div className="columns is-desktop">
              <div className="column is-8-desktop">
                <h1 className="mb-2 body-large-after-tablet">
                  {project.title}
                </h1>
                <h2 className="h-1">{project.subtitle}</h2>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className="columns is-desktop">
              <div className="column is-5-desktop">
                <div className="mb-3">
                  <h2 className="body-large">The work</h2>
                </div>
                <div
                  className="user-content"
                  dangerouslySetInnerHTML={{
                    __html: project.description,
                  }}
                ></div>
              </div>
              <div className="column is-offset-1-desktop is-5-desktop">
                <div className="mb-3">
                  <h2 className="body-large">Our roles</h2>
                </div>
                <ul className={styles.roles}>
                  {project.roles.map(role => (
                    <li key={`role-${role.id}`}>{role.title}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ZeusModules data={project.zeusModules} />
        <HeadingCta data={headingCta} />
      </div>

      <WorkNav slug={project.slug} />
    </Layout>
  )
}

export const query = graphql`
    query($slug: String!) {
        craft {
            work: entry(slug: [$slug], section: "work") {
                title
                slug
                id
                seomatic {
                    metaTagContainer
                    metaLinkContainer
                    metaTitleContainer
                    metaJsonLdContainer
                }
                ... on Craft_work_work_Entry {
                    subtitle
                    description
                    roles {
                        id
                        title
                    }
                    coverImages {
                        ... on Craft_coverImages_BlockType {
                            ratio_16x9 {
                                id
                                url
                                width
                                height
                                ... on Craft_zjContent_Asset {
                                    altText
                                }
                            }
                            ratio_4x3 {
                                id
                                url
                                width
                                height
                                ... on Craft_zjContent_Asset {
                                    altText
                                }
                            }
                        }
                    }
                    zeusModules {
                        ...ZeusModules
                    }
                    headingCta {
                        ...HeadingCta
                    }
                }
            }
        }
    }
`
