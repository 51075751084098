//
// This component displays prev/next works based on the slug its given
// (excluding any works that are set to "Coming Soon")
//
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Image from "./image"
import styles from "./workNav.module.scss"

const nextItem = (i, arr) => {
  i = i + 1 // increase i by one
  i = i % arr.length // if we've gone too high, start from `0` again
  return arr[i] // give us back the item of where we are now
}

const prevItem = (i, arr) => {
  if (i === 0) {
    // i would become 0
    i = arr.length // so put it at the other end of the array
  }
  i = i - 1 // decrease by one
  return arr[i] // give us back the item of where we are now
}

const getCoverImage = (images, socialImage) => {

  let imageToUse = images.ratio_16x9[0];
  if (imageToUse.url.includes('.mp4')) {
    imageToUse.url = socialImage.url;
    imageToUse.crop = true;
  }
  return imageToUse;
}


const WorkNav = props => {
  const data = useStaticQuery(
    graphql`query WorksNav {
        craft {
            featuredWork: entries(section: "work") {
                ... on Craft_work_work_Entry {
                    id
                    title
                    slug
                    uri
                    coverImages {
                        ... on Craft_coverImages_BlockType {
                            ratio_16x9 {
                                id
                                url
                                width
                                height
                                ... on Craft_zjContent_Asset {
                                    altText
                                }
                            }
                            ratio_4x3 {
                                id
                                url
                                width
                                height
                                ... on Craft_zjContent_Asset {
                                    altText
                                }
                            }
                            ratio_1x1 {
                                id
                                url
                                width
                                height
                                ... on Craft_zjContent_Asset {
                                    altText
                                }
                            }
                            ratio_3x4 {
                                id
                                url
                                width
                                height
                                ... on Craft_zjContent_Asset {
                                    altText
                                }
                            }
                        }
                    }
                    socialImage {
                        id
                        url
                        width
                        height
                        ... on Craft_athenaContent_Asset {
                            altText
                        }
                    }
                }
            }
        }
    }
    `
  )
  const works = data.craft.featuredWork
  const liveWork = works.filter(
    item =>
      item != null
  )

  const index = liveWork
    .map(item => {
      return item.slug
    })
    .indexOf(props.slug)
  const workIndex = (index === -1) ? 0 : index;

  const nextWork = nextItem(workIndex, liveWork)
  const prevWork = prevItem(workIndex, liveWork)
  const prevThumbnail = getCoverImage(prevWork.coverImages[0], prevWork.socialImage[0])
  const nextThumbnail = getCoverImage(nextWork.coverImages[0], nextWork.socialImage[0])


  return (
    <div className={styles.nav}>
      <div className="wrapper">
        <div className={`columns is-tablet ${styles.columns}`}>
          <div className="column is-6-tablet">
            <Link to={`/${nextWork.uri}`}>
              <div className={styles.navNext}>
                <div className={`body-large-after-desktop ${styles.text}`}>
                  <span>Next</span>
                  <br />
                  <span className={styles.textTitle}>{nextWork.title}</span>
                </div>
                <div className={styles.imageWrapper}>
                  <Image
                    width={nextThumbnail.width}
                    height={nextThumbnail.height}
                    // Excluding the gutters to account for scaling on hover with CSS
                    sizes="(min-width: 1344px) 690px, (min-width: 768px) calc(50vw), calc(100vw)"
                    url={nextThumbnail.url}
                    alt={nextThumbnail.altText}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div className="column is-6-tablet">
            <Link to={`/${prevWork.uri}`}>
              <div className={styles.navPrev}>
                <div className={`body-large-after-desktop ${styles.text}`}>
                  <span>Previous</span>
                  <br />
                  <span className={styles.textTitle}>{prevWork.title}</span>
                </div>
                <div className={styles.imageWrapper}>
                  <Image
                    width={prevThumbnail.width}
                    height={prevThumbnail.height}
                    sizes="(min-width: 1344px) 690px, (min-width: 768px) calc(50vw), calc(100vw - 2.5rem)"
                    url={prevThumbnail.url}
                    alt={prevThumbnail.altText}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkNav
